import React, { useEffect, useState } from "react";
import type { NextPage } from "next";
import Image from "next/image";
import { t, Trans } from "@lingui/macro";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Select from "react-select";
import { wrapper } from "@/app/store";
import { Client, Testimonial } from "@/app/dto/clients";
import {
  clientsApi,
  getSuppliers,
  getTestimonials,
  useGetCitiesQuery,
  useGetRegionsQuery,
} from "@/services/clients";
import { useCreateSupplierLeadMutation } from "@/app/services/leads";

import Hero from "@/components/Home/Hero";
import ProvidersCTALanding from "@/components/commons/ProvidersCTALanding";
import Collapse from "@/components/commons/Collapse";
import Testimony from "@/components/commons/Testimony";
import { getLocale } from "@/utils/locale";
import SuccessModal from "@/components/commons/SuccessModal";
import { GeoUnit } from "@/app/services/categories";
import SEOinPage from "@/components/commons/SEOinPage";

interface Props {
  suppliers: Client[];
  testimonials: Testimonial[];
}

const ProvidersLanding: NextPage<Props> = ({ suppliers, testimonials }) => {
  const locale = getLocale();
  const [createSupplierLead] = useCreateSupplierLeadMutation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [currentState, setCurrentState] = useState<GeoUnit | null | undefined>(
    null
  );

  const [currentCity, setCurrentCity] = useState<GeoUnit | null | undefined>(
    null
  );

  const [isValid, setIsValid] = useState<boolean>(true);

  const lcl = locale ?? "es-CL";

  const { data: states } = useGetRegionsQuery(lcl);
  const { data: cities } = useGetCitiesQuery(
    { locale: lcl, parent_id: currentState?.id },
    {
      skip: !currentState,
    }
  );

  const handleValidationForm = () => {
    if (currentState && currentCity) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (!executeRecaptcha) {
      console.error("theres no captcha");
      return;
    }

    data.geo_unit_id = currentCity?.id;

    if (currentCity) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

    if (isValid) {
      try {
        const token = await executeRecaptcha("supplier_lead");
        if (!token) {
          console.error("No existe token");
          return;
        }
        await createSupplierLead({ token, locale, lead: data });
        setIsOpenModal(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const btnFunction = (e) => {
    let form = document.getElementById("form");
    e.preventDefault(); // Stop Page Reloading
    form && form.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const SEOData = {
    description: t`SEO suppliers landing description`,
    keywords: t`SEO suppliers landing keywords`,
    h2: t`SEO suppliers landing h2`,
    h3: t`SEO suppliers landing h3`,
  };

  return (
    <>
      <SEOinPage data={SEOData} />
      <Hero
        title={t`SEO suppliers landing h1`}
        subtitle={t`Keep your equipment at maximum production`}
        imageUrl={`${
          locale === "pt-BR"
            ? "/landing/HeroImageProviders-br.png"
            : "/landing/HeroImageProviders.png"
        }`}
        imageAlt="Camión amarillo"
        btnText={t`I want to register!`}
        btnFunction={btnFunction}
        arrowAnchor="benefits"
        imageMobileUrl={`${
          locale === "pt-BR"
            ? "/landing/HeroImageProvidersMobile-br.png"
            : "/landing/HeroImageProvidersMobile.png"
        }`}
        imageHeight={621}
        imageWidth={895}
      >
        <>
          <h2 className="text-center text-3xl font-black">
            <Trans>
              Do your industrial machines spend more time parked than leased?
            </Trans>
          </h2>
          <h3 className="text-center text-xl mt-3">
            <Trans>
              At RendaloMaq we help you rent or sell them so you can get more
              out of them
            </Trans>
          </h3>
          <div className="grid grid-cols-3 mt-12">
            <div className="text-center">
              <Image
                height={100}
                width={97}
                alt="Ícono costo 0"
                src="/icons/costo0.svg"
              ></Image>
              <h3 className="text-xl font-bold mt-10">
                <Trans>Free</Trans>
              </h3>
            </div>
            <div className="text-center">
              <Image
                height={100}
                width={97}
                alt="Ícono sin comisiones"
                src="/icons/no-commissions.svg"
              ></Image>
              <h3 className="text-xl font-bold mt-10">
                <Trans>No commissions</Trans>
              </h3>
            </div>
            <div className="text-center">
              <Image
                height={100}
                width={97}
                alt="Ícono sin letra pequeña"
                src="/icons/no-small-text.svg"
              ></Image>
              <h3 className="text-xl font-bold mt-10">
                <Trans>No small print</Trans>
              </h3>
            </div>
          </div>
        </>
      </Hero>

      <section
        id="benefits"
        className="max-w-[90%] sm:max-w-7xl mx-auto py-8 sm:px-4 lg:py-14"
      >
        <h2 className="text-center text-2xl font-bold mb-9">
          <Trans>Also, access these benefits</Trans>
        </h2>
        <article className="flex flex-col md:flex-row items-center mb-24">
          <div className="w-full order-2 md:order-1 md:w-1/2">
            <h3 className="text-2xl font-semibold">
              <Trans>
                Digital platform that will allow you to monitor the status of
                your machines in real time
              </Trans>
            </h3>
            <button onClick={btnFunction} className="btn-black mt-4">
              <Trans>I want to be a part!</Trans>
            </button>
          </div>
          <div className="order-1 md:order-2 w-full md:w-1/2">
            <div className="relative w-full">
              <Image
                width={624}
                height={411}
                layout="responsive"
                unoptimized={true}
                src={
                  locale !== "pt-BR"
                    ? "/phone-notification.png"
                    : "/phone-notification-br.png"
                }
                alt="Imagen de un teléfono mostrando en su pantalla la app de RendaloMaq y una notificación, en la notificación se lee '¡Tu máquina va en camino!'"
              ></Image>
            </div>
          </div>
        </article>
        <article className="flex flex-col md:flex-row items-center mb-24">
          <div className="w-full md:w-1/2">
            <div className="relative w-full">
              <Image
                width={624}
                height={403}
                unoptimized={true}
                src="/geo-landing.png"
                alt="Imagen representando la geolocalización de las máquinas"
              ></Image>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-2xl font-semibold">
              <Trans>Some attributes that you can find with RendaloMaq:</Trans>
            </h3>
            <ul className="my-5">
              <li>
                <Trans>- GPS for each of your machines</Trans>
              </li>
              <li>
                <Trans>- Hour meter and odometer</Trans>
              </li>
              <li>
                <Trans>- Reports on your operation, and much more!</Trans>
              </li>
            </ul>
            <p>
              <Trans>Do you need something specific? Lets talk!</Trans>
            </p>
            <button onClick={btnFunction} className="btn-black mt-4">
              <Trans>I want to register!</Trans>
            </button>
          </div>
        </article>
        <article className="flex flex-col md:flex-row items-center mb-24">
          <div className="w-full order-2 md:order-1 md:w-1/2">
            <h3 className="text-2xl font-semibold">
              <Trans>
                Do not complicate yourself! With RendaloMaq you will not have to
                make transfer and security efforts if you need it and we will
                even help you manage with your potential clients.
              </Trans>
            </h3>
            <button onClick={btnFunction} className="btn-black mt-4">
              <Trans>I want to be a supplier!</Trans>
            </button>
          </div>
          <div className="order-1 md:order-2 w-full md:w-1/2">
            <div className="relative w-full">
              <Image
                width={590}
                height={434}
                unoptimized={true}
                src="/client.png"
                alt="Imagen de un caucásico, con barba. El hombre usa casco de protección, un chaleco reflectante y camisa de cuadros. Mira hacia un dispositivo móvil que tiene en la mano."
              ></Image>
            </div>
          </div>
        </article>
      </section>

      <section
        id="hdiw"
        className="max-w-[90%] sm:max-w-7xl mx-auto py-8 sm:px-4 lg:py-14"
      >
        <div className="grid md:grid-cols-3 gap-4">
          <div className="bg-white p-4 rounded-md">
            <h2 className="text-3xl mb-4">
              <Trans>How does it work?</Trans>
            </h2>
            <p className="mb-2">
              <Trans>
                Contact us and tell us if you want to lease and/or sell
                machinery.
              </Trans>
            </p>
            <p className="mb-2">
              <Trans>
                We will call you to coordinate a meeting and thus, discuss
                details, answer your questions and specify some points.
              </Trans>
            </p>
            <p>
              <Trans>
                If you agree, we will assign you as a provider in our network,
                where we will lease and/or sell your machines when our customers
                request it and you authorize it.
              </Trans>
            </p>
          </div>
          <div className="bg-white p-4 rounded-md">
            <h2 className="text-3xl mb-4">
              <Trans>What do you earn?</Trans>
            </h2>
            <p className="mb-2">
              <Trans>
                Clientes, RendaloMaq te llamará cuando haya arrendatarios o
                compradores interesados sin necesidad de que tú los tengas que
                buscar.
              </Trans>
            </p>
            <p className="mb-2">
              <Trans>
                Control, you decide the price, quantity and type of machines to
                rent or sell.
              </Trans>
            </p>
            <p>
              <Trans>
                Data, our software for industrial machines shows you valuable
                information about the status of your equipment in real time.
              </Trans>
            </p>
          </div>
          <div className="bg-white p-4 rounded-md">
            <h2 className="text-3xl mb-4">
              <Trans>What do we gain?</Trans>
            </h2>
            <p className="mb-2">
              <Trans>
                Customers, we receive a percentage of profit on each lease or
                sale, although you do not pay it directly.
              </Trans>
            </p>
            <p>
              <Trans>
                Expansion, we want to bring solutions to the industrial sector
                throughout Latin America, we are already in Chile, Mexico and
                Brazil, with your vote of confidence we will continue to grow.
              </Trans>
            </p>
          </div>
        </div>
      </section>

      <section className="lg:max-w-7xl mx-auto lg:px-4 lg:py-14">
        <ProvidersCTALanding btnText={null} buttonFunction={btnFunction} />
      </section>

      <section
        id="faq"
        className="max-w-[90%] sm:max-w-7xl mx-auto py-8 sm:px-4 lg:py-14"
      >
        <h2 className="text-center text-3xl font-bold">
          <Trans>Frequently asked questions</Trans>
        </h2>
        <article className="mt-12 mb-4">
          <Collapse title={t`What is RendaloMaq?`}>
            <>
              <p>
                <Trans>
                  We are the first digital agent for the rental and sale of
                  machinery in Latam.
                </Trans>
              </p>
              <p>
                <Trans>
                  We do not have machines, nor will we have them, because we are
                  not your competition, we are your allies, with whom you can
                  rent and/or sell your machines at your price, your time and
                  your stock.
                </Trans>
              </p>
              <p>
                <Trans>
                  What does this mean? That we get people interested in renting
                  or buying equipment like yours and thus specify its use
                  through a lease or sale.
                </Trans>
              </p>
              <p>
                <Trans>
                  What is the advantage for you? RendaloMaq takes care of
                  absolutely everything, that is, you continue to earn the total
                  you ask for renting the equipment, but you do not invest time
                  or effort in negotiations, transfers or paperwork.
                </Trans>
              </p>
              <p>
                <Trans>
                  We pay you, coordinate with the client and take
                  responsibility.
                </Trans>
              </p>
              <p>
                <Trans>
                  Forget about delinquencies or other intermediaries that charge
                  you an additional commission, because at RendaloMaq we will be
                  your best client, since we assume the commercial risk.
                </Trans>
              </p>
              <p>
                <Trans>
                  In addition, we provide you with our fleet management and
                  geolocation platform, so that you can be up to date on the
                  status of your assets at all times.
                </Trans>
              </p>
            </>
          </Collapse>
        </article>
        <article className="mb-4">
          <Collapse
            title={t`Do they really not charge me additional commission?`}
          >
            <>
              <p>
                <Trans>
                  Really! You have control of the rental or sale prices of your
                  equipment and at RendaloMaq we pay them.
                </Trans>
              </p>
              <p>
                <Trans>
                  Of course we can always advise you with the market rates so
                  that your leases have greater profitability and thus manage to
                  keep your machines busy all the time.
                </Trans>
              </p>
            </>
          </Collapse>
        </article>
        <article className="mb-4">
          <Collapse title={t`What does it mean that they give me GPS?`}>
            <>
              <p>
                <Trans>
                  In order to be clear about the machines available for rent and
                  so that you always know where your equipment is, we install a
                  GPS on the machines you decide to rent, at no additional cost.
                </Trans>
              </p>
              <p>
                <Trans>
                  In the same way, consider that you will always have control of
                  the information, at RendaloMaq we will only have access to the
                  availability of your machines, the rest of the information is
                  protected with high security standards and only for your
                  consultation.
                </Trans>
              </p>
            </>
          </Collapse>
        </article>
        <article className="mb-4">
          <Collapse title={t`What is vendor software all about?`}>
            <>
              <p>
                <Trans>
                  It is a digital platform developed by RendaloMaq that shows
                  you real-time information about your machines, such as the
                  occupancy rate, profit per machine, location and rental time.
                </Trans>
              </p>
            </>
          </Collapse>
        </article>
        <article className="mb-4">
          <Collapse title={t`What is RendaloMaq in charge of?`}>
            <>
              <p>
                <Trans>
                  If you lease your machines through us, we take care of:
                </Trans>
              </p>
              <ul>
                <li>
                  <Trans>Get you interested customers.</Trans>
                </li>
                <li>
                  <Trans>
                    The round trip transportation of your machines, in all
                    regions of the country.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Insurance, to protect your machines during the transfer and
                    stay on the construction site.
                  </Trans>
                </li>
                <li>
                  <Trans>Paperwork and dealing with the client.</Trans>
                </li>
              </ul>
            </>
          </Collapse>
        </article>
        <article className="mb-4">
          <Collapse
            title={t`Does leasing my machines through RendaloMaq put me under any special obligation?`}
          >
            <>
              <p>
                <Trans>
                  No, it will just make it easier for you to lease your machines
                  more often. Your assets remain 100% yours and you can do
                  whatever you want with them.
                </Trans>
              </p>
              <p>
                <Trans>
                  In a nutshell, we make it easy for you to monitor and
                  calculate profit per machine, on an easy-to-use platform,
                  backed by data that you can access anytime, anywhere.
                </Trans>
              </p>
            </>
          </Collapse>
        </article>
        <article className="mb-4">
          <Collapse
            title={t`What guarantees does RendaloMaq give me for leasing my machines?`}
          >
            <>
              <p>
                <Trans>
                  At RendaloMaq we know that your investment in industrial
                  equipment was high and cost you effort, that is why we take
                  the rental, sale or even transfer of your equipment very
                  seriously and responsibly.
                </Trans>
              </p>
              <p>
                <Trans>
                  We have personalized attention, insurance for your machines
                  during transfers and work stays. More than 250 companies have
                  preferred us with excellent results and experiences, join you
                  too!
                </Trans>
              </p>
            </>
          </Collapse>
        </article>
      </section>

      <section className="max-w-[90%] sm:max-w-7xl mx-auto py-8 sm:px-4 lg:py-14">
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-12 md:mb-0">
            <div className="mb-24 text-center md:text-left">
              <h2 className="text-2xl font-semibold">
                <Trans>You are in good company</Trans>
              </h2>
              <h3 className="text-4xl">
                {" "}
                <span className="font-bold text-safety-orange">+400 </span>
                <Trans>clients satified</Trans>
              </h3>
            </div>
            <div className="grid grid-cols-3">
              {suppliers?.slice(0, 6).map((supplier, index) => (
                <div className="text-center" key={`supplier_${index}`}>
                  <div className="relative mx-auto text-center grayscale hover:grayscale-0 w-[150px] md:w-[100px] h-16 mb-4">
                    <Image
                      layout="fill"
                      objectFit="contain"
                      src={supplier.logo}
                      alt={supplier.name}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-1/2">
            {testimonials?.slice(0, 2).map((testimonial, index) => (
              <Testimony
                key={`testimonial_${index}`}
                testimonial={testimonial}
              />
            ))}
          </div>
        </div>
      </section>

      <section
        id="form"
        className="w-full relative bg-[url('/bg-orange-inverted.svg')] bg-bottom bg-no-repeat bg-full py-16"
      >
        <div className="w-full">
          <h2 className="text-center font-bold text-4xl mb-9">
            <Trans>Ready to get more clients?</Trans>
          </h2>
          <div className="w-[90%] md:w-[600px] mx-auto bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-2xl font-bold mb-6">
                <Trans>Sign up as a provider</Trans>
              </h3>
              <form action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      <Trans>Name</Trans>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        {...register("first_name", { required: true })}
                        className="shadow-sm border focus:ring-safety-orange px-4 py-3 focus:safety-orange block w-full sm:text-sm 
                        border-gray-300 rounded-md"
                        placeholder="Paz"
                      />
                      {errors.first_name && (
                        <small className="text-red-500">
                          <Trans>This field is required</Trans>
                        </small>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      <Trans>Last name</Trans>
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        {...register("last_name", { required: true })}
                        className="shadow-sm border focus:ring-safety-orange px-4 py-3 focus:safety-orange block w-full sm:text-sm 
                        border-gray-300 rounded-md"
                        placeholder="Rodríguez"
                      />
                      {errors.last_name && (
                        <small className="text-red-500">
                          <Trans>This field is required</Trans>
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="company_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <Trans>Business name</Trans>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="company_name"
                      id="company_name"
                      {...register("company_name", { required: true })}
                      className="shadow-sm border focus:ring-safety-orange px-4 py-3 focus:safety-orange block w-full sm:text-sm 
                      border-gray-300 rounded-md"
                      placeholder="Rodriguez y asociados SpA"
                    />
                    {errors.company_name && (
                      <small className="text-red-500">
                        <Trans>This field is required</Trans>
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <Trans>Email</Trans>
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      pattern=".+@.+\..+"
                      {...register("email", { required: true })}
                      className="shadow-sm border focus:ring-safety-orange px-4 py-3 w-full focus:safety-orange block sm:text-sm border-gray-300 rounded-md"
                      placeholder="prodriguez@mail.com"
                    />
                    {errors.email && (
                      <small className="text-red-500">
                        <Trans>This field is required</Trans>
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <Trans>Phone</Trans>
                  </label>
                  <div className="mt-1">
                    <input
                      type="phone"
                      name="phone"
                      id="phone"
                      {...register("phone", { required: true })}
                      className="shadow-sm border focus:ring-safety-orange px-4 py-3 w-full focus:safety-orange block sm:text-sm border-gray-300 rounded-md"
                      placeholder="+5X XXXX XXXX"
                    />
                    {errors.phone && (
                      <small className="text-red-500">
                        <Trans>This field is required</Trans>
                      </small>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    <Trans>State</Trans>
                  </label>
                  <Select
                    className="mt-1"
                    options={states}
                    placeholder={t`Select...`}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id.toString()}
                    value={currentState}
                    onChange={(option) => {
                      setCurrentState(option as GeoUnit);
                      setCurrentCity(null);
                    }}
                  />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    <Trans>City</Trans>
                  </label>
                  <Select
                    className="mt-1"
                    options={cities}
                    placeholder={t`Select...`}
                    value={currentCity}
                    name="geo_unit_id"
                    onChange={(option) => {
                      setCurrentCity(option as GeoUnit);
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id.toString()}
                  />
                  {!isValid && (
                    <small className="text-red-500">
                      <Trans>This field is required</Trans>
                    </small>
                  )}
                </div>
                <button
                  onClick={() => handleValidationForm()}
                  type="submit"
                  className="btn-primary rounded-md w-full mt-4"
                >
                  <Trans>Sign me up</Trans>
                </button>
                <small className="mt-4 block text-gray-400 text-center">
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a
                    className="link"
                    href="https://policies.google.com/privacy"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a className="link" href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{" "}
                  apply.
                </small>
              </form>
            </div>
          </div>
        </div>
      </section>

      <SuccessModal
        open={isOpenModal}
        setOpen={setIsOpenModal}
        title={t`Your request has been sent!`}
        text={t`One of our agents will contact you soon`}
        btnText={t`Thanks`}
      />
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  (store): any =>
    async (context: any) => {
      const { locale } = context;

      const lcl = locale ?? "es-CL";

      store.dispatch(getSuppliers.initiate(lcl));
      store.dispatch(getTestimonials.initiate(lcl));

      await Promise.all(clientsApi.util.getRunningOperationPromises());

      const suppliers = store.getState().clientsApi.queries[
        `getSuppliers("${lcl}")`
      ].data as Client[];

      const testimonials = store.getState().clientsApi.queries[
        `getTestimonials("${lcl}")`
      ].data as Testimonial[];

      return {
        props: {
          suppliers: suppliers,
          testimonials: testimonials,
        },
      };
    }
);

export default ProvidersLanding;
